import React from 'react';
import MaskedInput from 'react-text-mask';

export interface TextMaskProps {
    inputRef: (ref: HTMLElement | null) => void;
  }
  
const PhoneMask: React.FC<TextMaskProps> = (props: TextMaskProps) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: MaskedInput) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
};

export default PhoneMask;
