import React from 'react';
import { greenPrimary } from '../VilaColors/colors';

export default function Copyright() {

    const copyright = {
        color: "#161c2d",
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '13px',
        padding: '20px',
        maxWidth: '960px',
        marginLeft: 'auto',
        marginRight: 'auto',

    };

    const politicaPrivacidade = {
        marginTop: '10px'
    }

    return (
        <div style={copyright}>
            <div style={{
                textAlign: 'center',
            }}>
                Copyright ©{new Date().getFullYear()} <a href="https://integracaomoskit.irmaosvila.com.br/" style={{ color: greenPrimary }}>https://integracaomoskit.irmaosvila.com.br</a>, TODOS OS DIREITOS RESERVADOS. Todo o conteúdo do site, todas as fotos, imagens, logotipos, marcas, dizeres, som, software, conjunto imagem, layout, aqui veiculados são de propriedade exclusiva da EMPRESA VILA LTDA. É vedada qualquer reprodução, total ou parcial, de qualquer elemento de identidade, sem expressa autorização. A violação de qualquer direito mencionado implicará na responsabilização cível e criminal nos termos da Lei. Safra São Francisco assistência funerária LTDA - CNPJ: 70.313.051/0001-59 - Av. Presidente Bandeira, 487, Alecrim, 59031-200 - Natal/RN - 4000-2002
                <div style={politicaPrivacidade}>
                    <a href="https://www.irmaosvila.com.br/politica-privacidade" style={{ color: greenPrimary }}>Política de Privacidade</a>
                </div>
            </div>
        </div>
    );
}