import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { LinearProgress } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';

import { useEffect } from 'react';

import SnackBar from '../../components/Snackbar/SnackBar';
import { createUser } from './services';

const useHelperTextStyles = makeStyles(() => ({
    root: {
        marginLeft: 0
    }
}));

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(16),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        width: '100%',
    },
    helperText: {
        marginLeft: 0,
    },
    progress: {
        marginTop: '1em',
    },
    divider: {
        margin: '1em 0',
    },
}));

export interface UserRequest {
   username: string;
   email: string;
   password: string;
   roles: Array<string>;
}

export default function CreateUser() {
    const classes = useStyles();
    const helperTextStyles = useHelperTextStyles();

    const [name, setName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isCommonUser, setIsCommonUser] = useState<boolean>(true);

    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

   
    const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [isSnackError, setSnackError] = useState<boolean>(false);

  
    const [nameError, setNameError] = useState<string | undefined>('');
    const [emailError, setEmailError] = useState<string | undefined>('');
    const [usernameError, setUsernameError] = useState<string | undefined>('');
    const [passwordError, setPasswordError] = useState<string | undefined>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string | undefined>('');


    const handleChangeName = (event: React.ChangeEvent<{ value: unknown }>) => {
        setName(event.target.value as string);
        setNameError('');
    };

    const handleChangeUserEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUserEmail(event.target.value as string);
        setEmailError('');
    };

    const handleChangeUsername = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUsername(event.target.value as string);
        setUsernameError('');
    };

    const handleChangePassword = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPassword(event.target.value as string);
        setPasswordError('');
    };

    const handleChangeConfirmPassword = (event: React.ChangeEvent<{ value: unknown }>) => {
        setConfirmPassword(event.target.value as string);
        setConfirmPasswordError('');
    };

    const handleChangeIsAdmin = (event: React.ChangeEvent<{}>, checked: boolean) => {
        setIsAdmin(checked);
    }
    const handleChangeIsCommonUser = (event: React.ChangeEvent<{}>, checked: boolean) => {
        setIsCommonUser(checked);
    }


    useEffect(() => {
        if(confirmPassword !== password){
            setConfirmPasswordError("A senha deve ser igual")
        }else{
            setConfirmPasswordError("");
        }
    }, [confirmPassword, password])


    const validateForm = async () => {
        if (name === '') {
            setNameError("Preencha o nome");
        }if(userEmail === ''){
            setEmailError("Preencha o email");
        }if(username === ''){
            setUsernameError("Preencha o usuário");
        }if(password === ''){
            setPasswordError("Preencha a senha");
        }
        if(confirmPassword === ''){
            setConfirmPasswordError("Confirme a senha");
        }
        if(name !== '' && userEmail !== '' && username !== '' && password !== '' && confirmPassword !== '' && (confirmPassword === password)){
            sendCreateUserRequest();
        }
    }

    const sendCreateUserRequest = async () => {
        setIsRequestLoading(true);
        let roles = ['user'];
        if(isAdmin){
            roles.push('admin');
        }
        const data: UserRequest = {
            username: username,
            email: userEmail,
            password: password,
            roles: roles
        }
        
        let createUserRequest = await createUser(data);
        setIsRequestLoading(false);
        if(createUserRequest.res){
            setSnackError(false);
            setSnackMessage("Sucesso!")
            setSnackOpen(true);
            cleanAllFields();
        }else{
            setSnackError(true);
            setSnackMessage(createUserRequest.data.message);
            setSnackOpen(true);
        }
    }


    const cleanAllFields = () => {
        setName('');
        setUserEmail('');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setIsAdmin(false);
    }

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <PersonAddIcon />
                <Typography component="h1" variant="h5">
                    Cadastrar Usuário
                </Typography>
       
                <div className={classes.form}>
                    <div style={{width: '100%', height: '20px'}}>{ isRequestLoading && (<LinearProgress/>)}</div>
                
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                fullWidth
                                id="firstName"
                                label="Nome"
                                autoFocus
                                value={name}
                                onChange={handleChangeName}
                                error={!!nameError}
                                helperText={nameError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                id="userEmail"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                onChange={handleChangeUserEmail}
                                value={userEmail}
                                error={!!emailError}
                                helperText={emailError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                id="username"
                                label="Usuário"
                                name="username"
                                onChange={handleChangeUsername}
                                value={username}
                                error={!!usernameError}
                                helperText={usernameError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                label="Senha"
                                name="password"
                                autoComplete="password"
                                type="password"
                                id="password"
                                onChange={handleChangePassword}
                                value={password}
                                error={!!passwordError}
                                helperText={passwordError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                label="Confirmar Senha"
                                name="confirmPassword"
                                autoComplete="password"
                                type="password"
                                id="confirm-password"
                                onChange={handleChangeConfirmPassword}
                                value={confirmPassword}
                                error={!!confirmPasswordError}
                                helperText={confirmPasswordError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <Divider className={classes.divider} />
                        <Typography component="h1" variant="h6">
                            Permissões
                        </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={isCommonUser} value="isCommon" color="primary" onChange={handleChangeIsCommonUser} />}
                                label="Comum"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={isAdmin} value="isAdmin" color="primary" onChange={handleChangeIsAdmin} />}
                                label="Administrador"
                            />
                        </Grid>
                    

                    </Grid>
                    <Grid container justify="flex-end">
                        <Grid item xs={12} sm={4}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => validateForm()}
                                disabled={isRequestLoading}
                            >
                                Cadastrar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <SnackBar message={snackMessage} isOpen={isSnackOpen} severity={isSnackError ? "error" : "success"} handleClose={() => setSnackOpen(false)}/>
        </Container>
    );
}