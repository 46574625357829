import React from 'react';
import { greenPrimary, greenSecondary } from './components/VilaColors/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Copyright from './components/Copyright/Copyright';
import { NavRoute } from './components/NavRoute/NavRoute';
import CreateUser from './pages/CreateUser/CreateUser';
import ListUsers from './pages/ListUsers/ListUsers';

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: greenPrimary
      },
      secondary: {
        main: greenSecondary
      }
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <NavRoute exact component={Home} path="/home" />
          <NavRoute exact component={CreateUser} path="/users/create" permission="ADMIN" />
          <NavRoute exact component={ListUsers} path="/users/list" permission="ADMIN" />

        </Switch>
        <Copyright />
      </Router>
    </ThemeProvider>
  );
}

export default App;
