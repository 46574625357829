import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { LinearProgress, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { CompanyOptions, getCompaniesList, getProductIdFromCompany, ListItemResponse } from './utils';
import { useEffect } from 'react';
import { createLead, getCollaboratorsList, getChannelsList } from './services';
import SnackBar from '../../components/Snackbar/SnackBar';
import PhoneMask from '../../components/Masks/PhoneMask';

const useHelperTextStyles = makeStyles(() => ({
    root: {
        marginLeft: 0
    }
}));

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(16),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        width: '100%',
    },
    helperText: {
        marginLeft: 0,
    },
    progress: {
        marginTop: '1em',
    },
}));

export interface LeadRequest {
    contact: {
        name: string;
        phone: string;
        email?: string;
    }
    deal: {
        product: number;
        company: number;
        channel: number;
        notes?: string;
    }
    collaborator: number;
    source: number;
}


export default function Home() {
    const classes = useStyles();
    const helperTextStyles = useHelperTextStyles();
    const [product, setProduct] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [channel, setChannel] = useState<string>('');
    const [clientName, setClientName] = useState<string>('');
    const [clientEmail, setClientEmail] = useState<string>('');
    const [clientPhone, setClientPhone] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [collaborator, setCollaborator] = useState<string>('');
    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
    const [companiesList, setCompaniesList] = useState<CompanyOptions>({});
    const [collaboratorsList, setCollaboratorsList] = useState<ListItemResponse[] | []>([]);
    const [channelsList, setChannelsList] = useState<ListItemResponse[] | []>([]);

    const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [isSnackError, setSnackError] = useState<boolean>(false);

    const [selectedProductError, setSelectedProductError] = useState<string | undefined>('');
    const [selectedCompanyError, setSelectedCompanyError] = useState<string | undefined>('');
    const [selectedChannelError, setSelectedChannelError] = useState<string | undefined>('');
    const [clientNameError, setClientNameError] = useState<string | undefined>('');
    const [clientPhoneError, setClientPhoneError] = useState<string | undefined>('');
    const [selectedCollaboratorError, setSelectedCollaboratorError] = useState<string | undefined>('');

    const handleChangeProduct = (event: React.ChangeEvent<{ value: unknown }>) => {
        setProduct(event.target.value as string);
        setSelectedProductError('');
    };
    const handleChangeCompany = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCompany(event.target.value as string);
        setSelectedCompanyError('');
    };

    const handleChangeChannel = (event: React.ChangeEvent<{ value: unknown }>) => {
        setChannel(event.target.value as string);
        setSelectedChannelError('');
    };

    const handleChangeClientName = (event: React.ChangeEvent<{ value: unknown }>) => {
        setClientName(event.target.value as string);
        setClientNameError('');
    };

    const handleChangeClientPhone = (event: React.ChangeEvent<{ value: unknown }>) => {
        setClientPhone(event.target.value as string);
        setClientPhoneError('');
    };

    const handleChangeClientEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
        setClientEmail(event.target.value as string);
    };

    const handleChangeCollaborator = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCollaborator(event.target.value as string);
        setSelectedCollaboratorError('');
    };

    const handleChangeNotes = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNotes(event.target.value as string);
    }

    const sendCollaboratorsListRequest = async () => {
        let collaboratorListRequest = await getCollaboratorsList();
        if (collaboratorListRequest.res) {
            setCollaboratorsList(collaboratorListRequest.data);
        }
    }

    const sendChannelsListRequest = async () => {
        let channelsListRequest = await getChannelsList();
        if (channelsListRequest.res) {
            setChannelsList(channelsListRequest.data);
        }
    }

    useEffect(() => {
        let companies = getCompaniesList(product);
        setCompaniesList(companies);
        setCompany('');
    }, [product])

    useEffect(() => {
        sendCollaboratorsListRequest();
    }, []);

    useEffect(() => {
        sendChannelsListRequest();
    }, []);

    const validateForm = () => {
        if (product === '') {
            setSelectedProductError("Selecione um produto");
        } if (company === '') {
            setSelectedCompanyError("Selecione uma empresa");
        }
        if (channel === '') {
            setSelectedChannelError("Selecione um canal");
        }
        if (clientName === '') {
            setClientNameError("Preencha o nome");
        }
        if (clientPhone === '') {
            setClientPhoneError("Preencha o telefone");
        }
        if (collaborator === '') {
            setSelectedCollaboratorError("Preencha o colaborador");
        }
        if (product !== '' && company !== '' && channel !== '' && clientName !== '' && clientPhone !== '' && collaborator !== '') {
            sendCreateLeadRequest();
        }
    }

    const sendCreateLeadRequest = async () => {
        let productId = getProductIdFromCompany(product, company);
        let body: LeadRequest = {
            contact: {
                name: clientName,
                phone: clientPhone,
                ...clientEmail && { email: clientEmail }
            },
            deal: {
                product: productId,
                company: parseInt(company),
                channel: Number(channel),
                ...notes && { notes: notes }
            },
            collaborator: parseInt(collaborator),
            source: 1
        }
        setIsRequestLoading(true);
        let createLeadRequest = await createLead(body);
        console.log('ON CREATE LEAD REQUEST')
        console.log(body)
        setIsRequestLoading(false);
        if (createLeadRequest.res) {
            setSnackError(false);
            setSnackMessage("Sucesso!")
            setSnackOpen(true);
            cleanAllFields();
        } else {
            setSnackError(true);
            setSnackMessage("Erro ao cadastrar lead. Verifique se os campos são válidos.");
            setSnackOpen(true);
        }
    }

    const cleanAllFields = () => {
        setProduct('');
        setCompany('');
        setChannel('');
        setClientName('');
        setClientPhone('');
        setClientEmail('');
        setCollaborator('');
        setNotes('');
        setChannel('');
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <PeopleAltIcon />
                <Typography component="h1" variant="h5">
                    Cadastro de Leads
                </Typography>

                <div className={classes.form}>
                    <div style={{ width: '100%', height: '20px' }}>{isRequestLoading && (<LinearProgress />)}</div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" className={classes.formControl} error={!!selectedProductError}>
                                <InputLabel id="demo-simple-select-outlined-label">Produto de interesse *</InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    labelId="select-product-label"
                                    id="select-product"
                                    label="Produto de interesse"
                                    value={product}
                                    onChange={handleChangeProduct}
                                    error={!!selectedProductError}
                                >
                                    <option aria-label="None" value="" />
                                    <optgroup label="Memorial Vila">
                                        <option value="prever_master">Prever Master</option>
                                        <option value="prever_prime">Prever Prime</option>
                                        <option value="corpos_imediato">Cremação de Corpo Imediato</option>
                                        <option value="corpos_futuro">Cremação de Corpo Futuro</option>
                                        <option value="restos_imediato">Cremação de Restos Imediato</option>
                                        <option value="restos_futuro">Cremação de Restos Futuro</option>
                                        <option value="modulo">Módulo Individual</option>
                                        <option value="jazigo_2">Jazigo de 2 gavetas</option>
                                        <option value="jazigo_3">Jazigo de 3 gavetas</option>
                                    </optgroup>
                                    <optgroup label="Plano Sempre">
                                        <option value="pleno">Sempre Pleno</option>
                                        <option value="mais">Sempre Mais</option>
                                        <option value="melhor">Sempre Melhor</option>
                                        <option value="unico">Aditivo Único</option>
                                        <option value="sempre_do_seu_jeito">Sempre do Seu Jeito </option>
                                    </optgroup>
                                    <optgroup label="Vila Pet">
                                        <option value="pet_imediato">Cremação Pet Imediato</option>
                                        <option value="pet_futuro">Cremação Pet Futuro</option>
                                    </optgroup>
                                </Select>
                                {selectedProductError !== '' && (
                                    <FormHelperText className={classes.helperText}>{selectedProductError}</FormHelperText>
                                )}

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" className={classes.formControl} error={!!selectedCompanyError}>
                                <InputLabel id="demo-simple-select-outlined-label">Empresa de destino *</InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    labelId="select-company-label"
                                    id="select-company"
                                    label="Empresa de destino"
                                    value={company}
                                    onChange={handleChangeCompany}
                                    disabled={product === ''}
                                    error={!!selectedCompanyError}
                                >
                                    <option aria-label="None" value="" />
                                    {Object.keys(companiesList).map(function (key, index) {
                                        return (
                                            <option value={companiesList[key]}>{key}</option>
                                        )
                                    })}
                                </Select>
                                {selectedCompanyError !== '' && (
                                    <FormHelperText className={classes.helperText}>{selectedCompanyError}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" className={classes.formControl} error={!!selectedChannelError}>
                                <InputLabel id="demo-simple-select-outlined-label">Canal de contato *</InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    label="Canal de contato"
                                    value={channel}
                                    onChange={handleChangeChannel}
                                    error={!!selectedChannelError}
                                >
                                    <option aria-label="None" value="" />
                                    {channelsList.length > 0 && (channelsList.map((c, index) => {
                                        return (
                                            <option value={c.id} key={index}>{c.label}</option>
                                        )
                                    }))}
                                </Select>
                                {selectedChannelError !== '' && (
                                    <FormHelperText className={classes.helperText}>{selectedChannelError}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="Nome do cliente"
                                autoFocus
                                value={clientName}
                                onChange={handleChangeClientName}
                                error={!!clientNameError}
                                helperText={clientNameError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="clientPhone"
                                label="Telefone do cliente"
                                name="clientPhone"
                                autoComplete="phone"
                                value={clientPhone}
                                onChange={handleChangeClientPhone}
                                error={!!clientPhoneError}
                                helperText={clientPhoneError}
                                FormHelperTextProps={{
                                    classes: {
                                        root: helperTextStyles.root
                                    }
                                }}
                                InputProps={{
                                    inputComponent: PhoneMask as any,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="clientEmail"
                                label="Email do cliente"
                                name="clientEmail"
                                autoComplete="email"
                                onChange={handleChangeClientEmail}
                                value={clientEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" className={classes.formControl} error={!!selectedCollaboratorError}>
                                <InputLabel id="demo-simple-select-outlined-label">Colaborador *</InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    labelId="select-collaborator-label"
                                    id="select-collaborator"
                                    label="Colaborador"
                                    value={collaborator}
                                    onChange={handleChangeCollaborator}
                                    error={!!selectedCollaboratorError}
                                >
                                    <option aria-label="None" value="" />
                                    {collaboratorsList.length > 0 && (collaboratorsList.map((c, index) => {
                                        return (
                                            <option value={c.id} key={index}>{c.label}</option>
                                        )
                                    }))}
                                </Select>
                                {selectedCollaboratorError !== '' && (
                                    <FormHelperText className={classes.helperText}>{selectedCollaboratorError}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                name="notes"
                                label="Observações"
                                type="notes"
                                id="notes"
                                onChange={handleChangeNotes}
                                value={notes}
                                inputProps={{ maxLength: 2000 }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item xs={12} sm={4}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined"
                                color="primary"
                                className={classes.submit}
                                onClick={() => cleanAllFields()}
                            >
                                Limpar
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => validateForm()}
                                disabled={isRequestLoading}
                            >
                                Cadastrar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <SnackBar message={snackMessage} isOpen={isSnackOpen} severity={isSnackError ? "error" : "success"} handleClose={() => setSnackOpen(false)} />
        </Container>
    );
}