import axios from 'axios';
import { Session } from '../../components/NavRoute/NavRoute';
import { LeadRequest } from './Home';
import { ListItemResponse } from './utils';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL
});

export const createLead = async (data: LeadRequest) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.post("api/crm/createLead", data, {
      headers: {
        "x-access-token": token
      }
    });
    return { res: true, data: response.data };
  } catch (error) {
    return { res: false, data: '' };
  }
};

export const getCollaboratorsList = async () => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get("api/crm/getCollaboratorsList", {
      headers: {
        "x-access-token": token
      }
    });

    const dataResponse: ListItemResponse[] = response.data;

    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, data: [] };
  }
};

export const getChannelsList = async () => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get("/api/crm/getChannelsList", {
      headers: {
        "x-access-token": token
      }
    });

    const dataResponse: ListItemResponse[] = response.data;

    return { res: true, data: dataResponse };
  } catch (error) {
    return { res: false, data: [] };
  }
};